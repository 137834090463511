/* Importação das fontes do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap');

/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif; /* Define a fonte padrão */
    
}
/* Corpo do documento */
body {
    background-color: #FEF5E7;
    height: 100vh; /* Alterado para 100% da altura da janela de visualização */
}

/* Efeitos de gradiente superior */
.efeitos-top, .efeitos-bottom {
    width: 100%;
    height: 6px;
    background: linear-gradient(to right, #3F59A6 0%, #3F59A6 25%, #3F59A6 50%, #3F59A6 75%, #3F59A6 100%);
}

/* Barra de navegação superior */
.nav-bar-top {
    width: 100%;
    height: 8vh;
    padding: 0 50px;
    background: #254670;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.barra-pesquisa {
    font-size: 10px;
    
}
/* Elementos da barra de navegação superior */
.nav-bar-top-left {
    display: flex;
    align-items: center;
}

.nav-bar-top-left .logo a {
    font-family: "MuseoModerno", sans-serif; /* Define a fonte do logotipo */
    font-weight: bold;
    font-size: 30px;
    color: #FEF5E7;
    margin-right: 25px;
}

.nav-bar-top-left .barra-pesquisa .input {
    border: 1px solid #ffffff;
    padding: 5px 10px;
    outline: none;
    transition: all 0.5s linear;
}

.nav-bar-top-right ul {
    display: flex;
    align-items: center;
    padding: 0;
}

.nav-bar-top-right ul li {
    display: inline-block; /* Coloca os itens da lista lado a lado */
    margin-right: 10px;
}

.login, .cadastro {
    border-radius: 5px;
    letter-spacing: 0.5px;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.login {
    background: #FEF5E7;
    font-size: 15px;
}

.cadastro {
    background: #FEF5E7;
    font-size: 15px;
}

.login:hover {
    background: #2CA7B9;
}

.cadastro:hover {
    background: #f58c0c;
}

.nav-bar-top-right {
    display: block;
    padding: 8px 10px;
    color: #666666;
}

/* Barra de navegação inferior */
.nav-bar-bottom {
    width: 100%;
    background: #254670;
    height: 7vh;
    padding: 0 50px;
}

.nav-bar-bottom ul {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-bar-bottom ul li a {
    color: #FEF5E7; 
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 15px;
    
}

/* Estilo do banner */
.banner img{
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}


.geral {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    gap: 17px;
    margin: 10px;

}


.card {
    background-color: #fff;
    width: 100%;
    height: 50px;
    border-radius: 12%;
    box-shadow: 4px 4px 12px #aaaa;
   margin: 10px;
   
    
  }

  .card div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px; 
    height: 20vh;
    margin-bottom:  -20px;
  }
  .card h1 {
    font-size: 2.2rem;
    margin-bottom:  10px;
  }
  .card h2 {
    font-size: 1.9rem;
    color: #aaaa;
    text-align: center;
    margin-bottom: 0px;

  }
  .card button {
    background-color: #2192FF;
    height: 30px;
    border: none;
    padding: 4px;
    width: 80%;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .card button:hover {
    background-color: #137de8;
    cursor: pointer;
  }


.intro {
    margin-top: 5rem;
    margin-bottom: -10rem;
    text-align: center;
}

.intro h1 {
    font-size: 35px;
    font-weight: 700;
    color: #000;
    margin-bottom: 2.2rem;
    margin-top: -10px;
}

.intro p {
    font-size: 1.5rem;
    color: #000;
}



/*.texto-card1 img, .texto-card2 img, .texto-card3 img, .texto-card4 img {*/
  /*  width: 100%; /* Define a largura da imagem como 100% do card */
  /*  height: 400px; /* Define a altura da imagem */
  /*  object-fit: cover;*/
   /* margin-bottom: 100px;*/
   /* Garante que a imagem se ajuste ao tamanho do card   object-fit: cover; */
   


/*.geral {
    width: 1960px;
    height: 1080px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}*/
.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 50px;
}



.card {
    width: 350px;
    height: 60vh;
    padding: 10px 0px;
    text-align: center;
    background: #fff;
    border-radius: 15px;
    box-shadow: 2px 5px 15px black;
    transition: transform 0.3s;
    margin-right: 40px; 
    margin-top: 200px;
    
    
}

.choose-role {
  background-color: #254670;
  align-items: center;
  display: flex;
  padding: 50px 400px;
  margin-top: 10px;
  margin: 50px;
  border-radius: 50px;

}
.choose-role button{
  margin: 50px;

}
.choose-role h2{
  color: #f1f7f5;
}

.geral .card:last-child {
    margin-right: 0; 
}

.geral .card:hover {
    transform: scale(1.010);
}


.geral .card .texto-card1 h2 {
    font-size: 30px;
    color: #000;
}
.geral .card .texto-card2 h2 {
    font-size: 30px;
    padding-bottom: 15px;
    color: #000;
}
.geral .card .texto-card3 h2 {
    font-size: 30px;
    padding-bottom: 15px;
    color: #000;
}
.geral .card .texto-card4 h2 {
    font-size: 30px;
    padding-bottom: 15px;
    color: #000;
}

.botao-verde a{
    color: #22fd1a;
}
.geral .card p span {
    font-size: 40px;
    padding: 0 5px;
    color:#000;
}

.geral .card p  {
    font-size: 20px;
    
    margin-bottom: 20px;
    color:#888888;
}

.geral .card .geral-card ul {
    padding: 10px 30px;
    padding-bottom: 20px;
}

.geral .card .geral-card ul li {
    list-style: none;
    padding: 10px 0;
    color:#505050;
    font-size: 14px;
}

.geral .card .geral-card ul li i {
    margin-right: 4px;
    
}

.geral .card .geral-card button {
    width: 150px;
    height: 40px;
    background: linear-gradient(135deg, #188648 5%, #1EA358 100%);
    border-radius: 20px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    display: flex; /* Adicionado */
    align-items: center; /* Adicionado */
    justify-content: center; /* Adicionado */
}

.geral .card .geral-card button:hover {
    background: #17ead9;
    letter-spacing: 1.2px;
}

.geral .card .geral-card button{
    text-decoration: none;
    color: #f7f7f7;
    font-size: 10px;
    text-align: center;
}


.geral_menu_login, .geral_menu_cadastro {
    background-color: #254670;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #000;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px auto;
    width:  600px;
    margin: 200px auto;
    
}

.input-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

.input-group label {
    text-align: left;
    margin-bottom: 8px;
    font-weight: normal;
    color: #fff;
}

.input-group input {
    border-radius: 3px;
    background-color: #808080;
    height: 40px;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: none;
    color: #fff;
}

.input-group button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
}

button:hover {
    background-color: #45a049;
}



/*parte do faq*/

.wrapper {
    max-width: 75%;
    margin: auto;
    margin-top: 50px;
  }
  
  .wrapper > p,
  .wrapper > h1 {
    margin: 1.5rem 0;
    text-align: center;
  }
  
  .wrapper > h1 {
    letter-spacing: 3px;
  }
  
  .accordion {
    background-color: white;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    font-size: 1.2rem;
    width: 100%;
    padding: 2rem 2.5rem;
    border: none;
    outline: none;
    transition: background-color 0.4s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .accordion i {
    font-size: 1.6rem;
  }
  
  .accordion.active,
  .accordion:hover {
    background-color: #f1f7f5;
  }
  
  .pannel {
    padding: 0 2rem 2.5rem 2rem;
    background-color: #f1f7f5;
    overflow: hidden;
    display: none;
  }
  
  .pannel.active {
    display: block; /* Alterando a propriedade de exibição apenas para os painéis ativos */
  }
  
  .pannel p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.2rem;
    line-height: 1.4;
  }
  
  .faq {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 10px 0;
  }
  
  .faq.active {
    border: none;
  }
  

  /* contato */
  .Contact {
    display: grid;
    height: 100px; /* Alterado para 100% da altura da janela de visualização */
    place-items: center;
    text-align: center;
    background: #FEF5E7;
  }
  
  .container-contact {
    position: relative;
    width: 700px; /* Aumentado para 600px */
    min-height: 500px; /* Adicionado para garantir que haja espaço suficiente */
    background: #254670;
    padding: 50px; /* Aumentado para 40px */
    border: 2px solid #444; /* Aumentado para 2px */
    border-radius: 10px; /* Aumentado para 10px */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .text {
    font-size: 30px; /* Aumentado para 30px */
    color: #000000;
    font-weight: 600; /* Aumentado para 600 */
  }
  
  .edit {
    position: absolute;
    right: 20px; /* Aumentado para 20px */
    top: 10px; /* Aumentado para 10px */
    font-size: 18px; /* Aumentado para 18px */
    color: #666;
    font-weight: 400;
    cursor: pointer;
  }
  
  .edit:hover {
    text-decoration: underline;
  }
  
  .star-widget {
    display: flex;
    justify-content: center; /* Para centralizar os botões */
  }

  .star-widget label {
  font-size: 60px; /* Aumentado para 60px */
  color: #444;
  padding: 15px; /* Aumentado para 15px */
  float: right;
  transition: all 0.2s ease;
}
  
  .star-widget input:not(:checked) ~ label:hover,
  .star-widget input:not(:checked) ~ label:hover ~ label {
    color: #fd4;
  }
  
  .star-widget input:checked ~ label {
    color: #fd4;
  }
  
  #rate-5:checked ~ .star-widget label {
    color: #fe7;
    text-shadow: 0 0 30px #952; /* Aumentado para 30px */
  }
  
  form header {
    font-size: 30px; /* Aumentado para 30px */
    color: #fe7;
    font-weight: 600; /* Aumentado para 600 */
    margin: 10px 0 30px 0; /* Aumentado para 10px no topo e 30px na parte inferior */
    transition: all 0.2s ease;
  }
  
  form .textarea textarea {
    height: 150px; /* Aumentado para 150px */
    width: 100%;
    outline: none;
    color: #eee;
    border: 2px solid #333; /* Aumentado para 2px */
    background: #222;
    padding: 15px; /* Aumentado para 15px */
    font-size: 20px; /* Aumentado para 20px */
    resize: none;
  }
  
  .form .btn {
    height: 60px; /* Aumentado para 60px */
    width: 100%;
    margin: 20px 0; /* Aumentado para 20px no topo e na parte inferior */
  }
  
  .form .btn button {
    height: 100%;
    width: 100%;
    border: 2px solid #444; /* Aumentado para 2px */
    outline: none;
    background: #222;
    color: #999;
    font-size: 20px; /* Aumentado para 20px */
    font-weight: 900; /* Aumentado para 600 */
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .form .btn button:hover {
    background: #1b1b1b;
  }
  

  /* cards do pessoal
  .container2 {
    margin-top: 500px;
    width: 540px;
    height: 100px;
    background-image: linear-gradient(#a734d2, #ff7581);
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    
    background-color: rgba(167, 52, 210, .5);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
*/
.container2 {

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 20vh;
  gap: 27px;
  margin: 90px;

}


.card2 {
    width: 400px;
    height: 30vh;
    padding: 0px 0px;
    text-align: center;
    background: #254670;
    border-radius: 15px;
    box-shadow: 2px 5px 15px black;
    transition: transform 0.3s;
    margin-top: 40px;
    margin-bottom: 30px;
    
}



.profile-img {
    width: 120px;
    border-radius: 50%;
    border: #1EA358 5px solid;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateY(-65px);
}

.container-text2 {
    margin-top: -35px;
    color: #FEF5E7;
}

h1.name {
    margin: 0;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 20px;
}

h3.desc {
    font-weight: 400;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 14px;
}

.address-icon {
   margin-top: -10px;
    width: 24px;
    filter: brightness(15);
}

.socials {
    margin: 20px 0;
}

.socials a {
    margin-right: 30px;

}

.socials a:last-child {
    margin-right: 0;
}

.socials img {
    width: 45px;
    transition: all .3s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 100%;
}

.socials img:hover {
    transform: scale(110%);
}

.socials img:active {
    transform: scale(103%);
}

/* Adicione estes estilos ao seu arquivo CSS */

.doctor-registration-form {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.doctor-registration-input-group {
  margin-bottom: 15px;
}

.doctor-registration-input-group label {
  display: block;
  margin-bottom: 5px;
}

.doctor-registration-input-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #1EA358;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #155d27;
}

.health-icon {
  width: 24px;
  vertical-align: middle;
  margin-right: 10px;
}

.profile-img {
  width: 120px;
  border-radius: 50%;
  border: #1EA358 5px solid;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateY(-65px);
}


.container22 {

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 20vh;
  gap: 27px;
  margin: 30px;

}


.card22 {
    width: 300px;
    height: 20vh;
    padding: 0px 0px;
    text-align: center;
    background: #254670;
    border-radius: 15px;
    box-shadow: 2px 5px 15px black;
    transition: transform 0.3s;
    margin-top: 40px;
    margin-bottom: 30px;
    
}



.profile-img {
    width: 120px;
    border-radius: 50%;
    border: #1EA358 5px solid;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateY(-65px);
}

.container-text2 {
    margin-top: -35px;
    color: #FEF5E7;
}

h1.name {
    margin: 0;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 20px;
}

h3.desc {
    font-weight: 400;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 14px;
}

.address-icon {
   margin-top: -10px;
    width: 24px;
    filter: brightness(15);
}


.contact input, select, textarea {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact button {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact button:hover {
  background-color: #45a049;
}
